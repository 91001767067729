import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import {Link, Image, Text, RichText} from '@sitecore-jss/sitecore-jss-react';

const ImageContentLink = ({fields, hasButtonLink = false, hasRichText = false, headingTagType = null, children}) => {

	const hasLink = !!fields.link && !!fields.link.value.href;
	const hasSecondaryLink = !!fields.secondaryLink && !!fields.secondaryLink.value.href;
	const hasDropdown = !!fields.contactUsItem;
	const selectRef = React.createRef();

	const content = (
		<React.Fragment>
			{fields.image.value.src && (
				<div className="img-container">
					<Image field={fields.image} />
				</div>
			)}
			<div className="content-container">
				<div className="content-container__grouper">
					<Text
						className={headingTagType ? 'is-h3' : ''}
						field={fields.title}
						tag={headingTagType || 'h2'}
					/>
					{hasRichText ? (
						<RichText field={fields.description}/>
					) : (
						<Text field={fields.description} tag="p" />
					)}
					{hasDropdown && (
						<React.Fragment>
							<select ref={selectRef} className="cta-select">
								{!!fields.default && <option selected disabled aria-disabled="true">{fields.default.value}</option>}
								{fields.contactUsItem.fields.options.map(contactOption => {
									const {fields, id} = contactOption;
									return (
										<option key={id} value={id}>{fields.title.value}</option>
									);
								})}
							</select>
							<Link field={fields.link} className="cta-button" onClick={() => localStorage.setItem('dropdownKey', selectRef.current.value)}/>
						</React.Fragment>
					)}
					{hasLink && !hasDropdown && (
						<div className="cta-grouping">
							{hasButtonLink || hasSecondaryLink ? (
								<React.Fragment>
									<Link field={fields.link} title={fields.link.value.title || fields.title.value} className="cta-button"/>
									{hasSecondaryLink && (
										<Link field={fields.secondaryLink} className="cta-button secondary" />
									)}
								</React.Fragment>
							) : (
								<span className="cta-button">{fields.link.value.text}</span>
							)}
						</div>
					)}
					{children}
				</div>
			</div>
		</React.Fragment>
	);

	return fields.hasFade && fields.hasFade.value ? (
		<ScrollAnimation animateIn="toFadeInUp" animateOnce delay={100} duration={0.5}>
			{content}
		</ScrollAnimation>
	) : content;
};

export default ImageContentLink;
