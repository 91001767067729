import React, {useState, useEffect} from 'react';
import {DefaultProps, PropTypes} from '../../../core/Utils/SitecoreFields';
import {Text} from '@sitecore-jss/sitecore-jss-react';
import ContentCard from '../ContentCard/ContentCard';
// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
// import "swiper/css";
// import "swiper/css/pagination"
// import Swiper core and required modules
import SwiperCore, {Navigation, Pagination} from 'swiper';
// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

const ContentCardListing = ({fields}) => {
	const [isMobile, setIsMobile] = useState(false);
	useEffect(() => {
		setIsMobile(window.innerWidth <= 760);
		window.addEventListener('resize', e => {
			setIsMobile(window.innerWidth <= 760);
			console.log(window.innerWidth <= 760, window.innerWidth);
		});
	}, []);
	return (
		<>
			<div class="swiper-button-prev"></div>
			<div class="swiper-button-next"></div>
			<div className="content-card-listing-wrapper">
				<Text className="listing-title" field={fields.title} tag="h2" />

				{fields.selectedCards && fields.selectedCards.length > 0 && (
					<Swiper
						slidesPerView={isMobile ? 1 : 3}
						spaceBetween={30}
						navigation={{
							nextEl: '.swiper-button-next',
							prevEl: '.swiper-button-prev',
						}}
					>
						{fields.selectedCards.map(card => (
							<SwiperSlide>
								<ContentCard fields={card.fields} />
							</SwiperSlide>
						))}
					</Swiper>
				)}
			</div>
		</>
	);
};

ContentCardListing.propTypes = PropTypes.SitecoreItem.inject({
	title: PropTypes.SingleLineText,
});
ContentCardListing.defaultProps = DefaultProps.SitecoreItem.inject({
	title: DefaultProps.SingleLineText,
});

export default ContentCardListing;
